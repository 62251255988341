<template>
    <nav class="nav">
        <a class="link" href="/admin/globalstats/">Global Stats</a>
        <router-link v-if="isAdmin" class="link" to="/admin/approvalqueue">Approval Queue</router-link>
        <a v-if="isAdmin" class="link" href="/admin/complianceregexes/">Compliance Regexes</a>
        <router-link v-if="isAdmin" class="link" to="/admin/emaillogs">Email Logs Lookup</router-link>
        <router-link class="link" to="/admin/agencyintern">Agency Intern</router-link>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import PageContainer from '@/components/PageContainer';

export default {
    name: 'Admin',
    components: { PageContainer },
    computed: mapGetters(['isAdmin']),
};
</script>

<style scoped lang="scss">
.nav {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}
.link {
    padding: 0 15px;
}
</style>
