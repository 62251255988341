import { includes } from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Dashboard from '@/views/Dashboard.vue';
import DomainDetail from '@/views/DomainDetail.vue';

import AdwordsStats from '@/views/AdwordsStats.vue';
import BillingHistory from '@/views/BillingHistory.vue';
import PaymentSettings from '@/views/PaymentSettings.vue';
import PurchaseDomain from '@/views/PurchaseDomain.vue';
import QLPStats from '@/views/QLPStats.vue';
import TeamSettings from '@/views/TeamSettings.vue';
import TransferDomain from '@/views/TransferDomain.vue';

import FourOhFour from '@/views/FourOhFour.vue';
import FourOhThree from '@/views/FourOhThree.vue';
import Login from '@/views/Login.vue';
import LoggedOut from '@/views/LoggedOut.vue';
import Redirector from '@/views/Redirector.vue';

import AgencyIntern from '@/views/admin/AgencyIntern.vue';
import ApprovalQueue from '@/views/admin/ApprovalQueue.vue';
import EmailLogs from '@/views/admin/EmailLogs.vue';

import PaymentList from './components/PaymentList';
import CreditCardForm from './components/CreditCardForm';
import EmptyRouterView from '@/views/EmptyRouterView';
import Admin from '@/views/Admin';
import Glossary from '@/views/Glossary.vue';
import GlossaryDetail from '@/components/glossary/GlossaryDetail';

import Campaigns from '@/views/Campaigns.vue';
import CampaignStats from '@/components/CampaignStats.vue';
import CampaignTools from '@/components/CampaignTools.vue';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '',
            component: Dashboard,
            meta: {
                name: 'Dashboard',
                hideBreadcrumbs: true,
            },
        },
        {
            path: '/login',
            name: 'process_login',
            component: Login,
            meta: {
                hideBreadcrumbs: true,
                restricted: true,
                dumb: true,
                login: true,
            },
        },
        {
            path: '/mirus',
            name: 'mirus_login',
            redirect: { name: 'process_login', query: { mirus_login: null } },
        },
        {
            path: '/redirect',
            name: 'redirector',
            component: Redirector,
            meta: {
                hideBreadcrumbs: true,
                restricted: true,
                dumb: true,
            },
        },

        {
            path: '/domains',
            component: EmptyRouterView,
            meta: { name: 'Domains', ignoreBreadcrumb: true },

            children: [
                {
                    path: '',
                    redirect: '/',
                    meta: { ignoreBreadcrumb: true },
                },
                {
                    path: 'purchase',
                    component: PurchaseDomain,
                    meta: { name: 'Purchase Domain' },
                },
                {
                    path: 'transfer',
                    component: TransferDomain,
                    meta: { name: 'Transfer Domain' },
                },
                {
                    path: ':domain_name',
                    component: EmptyRouterView,
                    meta: { paramName: 'domain_name' },

                    children: [
                        {
                            path: '',
                            component: DomainDetail,
                            meta: { ignoreBreadcrumb: true },
                        },
                        {
                            path: ':tab?',
                            meta: { ignoreBreadcrumb: true },
                            component: DomainDetail,
                        },
                    ],
                },
            ],
        },

        {
            path: '/glossary',
            component: Glossary,
            meta: {
                name: 'Glossary',
                dumb: true,
                restricted: true,
            },
        },
        {
            path: '/glossary/:keyword',
            component: GlossaryDetail,
            meta: {
                name: 'Glossary Detail',
                dumb: true,
                restricted: true,
            },
        },

        {
            path: '/qlp',
            component: QLPStats,
            beforeEnter: requireNotIntern,
            meta: { name: 'Landing Pages' },
        },
        {
            path: '/campaigns',
            component: Campaigns,
            beforeEnter: requireNotIntern,
            meta: { ignoreBreadcrumb: true },
            children: [
                {
                    path: '/',
                    component: CampaignStats,
                    meta: { name: 'Campaign Stats' },
                },
                {
                    path: 'tools',
                    component: CampaignTools,
                    meta: { name: 'Campaign Tools' },
                },
            ],
        },
        {
            path: '/adwords',
            component: AdwordsStats,
            beforeEnter: requireNotIntern,
            meta: { name: 'Google Ads' },
        },

        {
            path: '/payment',
            component: PaymentSettings,
            meta: { name: 'Payment Settings' },
            children: [
                {
                    path: '/',
                    component: PaymentList,
                    meta: { ignoreBreadcrumb: true },
                },
                {
                    path: 'edit/:id',
                    component: CreditCardForm,
                    props: true,
                    meta: { paramName: 'id' },
                },
                {
                    path: 'new',
                    component: CreditCardForm,
                    props: { isNew: true },
                    meta: { name: 'Add Card' },
                },
            ],
        },
        {
            path: '/billing',
            component: BillingHistory,
            meta: { name: 'Billing History' },
        },
        {
            path: '/team',
            component: TeamSettings,
            meta: { name: 'Team Settings' },
        },
        // {
        //     path: '/profile',
        //     component: Profile,
        //     meta: { name: 'Profile Overview' },
        // },
        {
            path: '/403',
            component: FourOhThree,
            meta: {
                restricted: true,
                hideBreadcrumbs: true,
            },
        },
        {
            path: '/logout',
            component: LoggedOut,
            meta: {
                hideBreadcrumbs: true,
                restricted: true,
                dumb: true,
                login: true,
            },
        },
        {
            path: '/admin',
            meta: { name: 'Admin', ignoreBreadcrumb: false },
            component: EmptyRouterView,
            children: [
                {
                    path: '',
                    component: Admin,
                    beforeEnter: requireMarketingOrAdmin,

                    meta: { hideBreadcrumbs: false, ignoreBreadcrumb: true, dumb: true },
                },
                {
                    path: 'agencyintern',
                    component: AgencyIntern,
                    beforeEnter: requireAdmin,
                    meta: {
                        name: 'Agency Intern',
                        restricted: true,
                        dumb: true,
                    },
                },
                {
                    path: 'approvalqueue',
                    component: ApprovalQueue,
                    beforeEnter: requireAdmin,
                    meta: {
                        name: 'Approval Queue',
                        restricted: true,
                        dumb: true,
                    },
                },
                {
                    path: 'emaillogs',
                    component: EmailLogs,
                    beforeEnter: requireAdmin,
                    meta: {
                        name: 'Email Logs Lookup',
                        dumb: true,
                        restricted: true,
                    },
                    children: [
                        {
                            path: ':email',
                            name: 'domain_email_logs',
                            meta: { ignoreBreadcrumb: true },
                        },
                    ],
                },
            ],
        },

        {
            path: '*',
            component: FourOhFour,
            meta: {
                hideBreadCrumbs: true,
                dumb: true,
                restricted: true,
            },
        },
    ],
});

router.afterEach(() => {
    // check if there is a new version of the app
    // refresh if there's a new version
    if (store.getters.newVersion) {
        store.commit('update_app');
    }
});

async function waitForUser() {
    return new Promise((resolve) => {
        if (store.getters.user()) {
            resolve(store.getters.user());
        } else {
            store.dispatch('get_user_bundle');
            store.watch(store.getters.user, function () {
                return resolve(store.getters.user());
            });
        }
    });
}

async function waitForAccountAgencyType() {
    return new Promise((resolve) => {
        if (store.getters.account_agency_type()) {
            resolve(store.getters.account_agency_type());
        } else {
            store.dispatch('get_account_bundle');
            store.watch(store.getters.account_agency_type, function () {
                return resolve(store.getters.account_agency_type());
            });
        }
    });
}

async function requireNotIntern(to, from, next) {
    await waitForUser();
    let agency_type = await waitForAccountAgencyType();
    if (agency_type !== 'Mirus Intern') {
        return next();
    } else {
        return next('/');
    }
}

async function requireAdmin(to, from, next) {
    let user = await waitForUser();
    if (user.user_type === 'MirusEmployee') {
        return next();
    } else {
        return next('/');
    }
}

async function requireMarketingOrAdmin(to, from, next) {
    let user = await waitForUser();
    if (user.user_type === 'MirusEmployee') {
        return next();
    } else if (includes(user.groups, 'Marketing Admin')) {
        return next();
    } else {
        return next('/');
    }
}

export default router;
