<template>
    <b-navbar toggleable="md" type="primary" class="depth-1" variant="white" sticky>
        <b-navbar-brand to="/">
            My
            <i>DA</i> Domain
        </b-navbar-brand>
        <b-navbar-toggle target="nav_collapse">
            <Icon i="bars" />
        </b-navbar-toggle>
        <b-collapse id="nav_collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/">Dashboard</b-nav-item>
                <b-nav-item-dropdown v-if="!restricted" text="Marketing" :disabled="isIntern">
                    <b-dropdown-item to="/campaigns">Campaigns</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown text="Support" right>
                    <b-dropdown-item href="mailto:support@mydadomain.com">
                        Email us at:
                        <br />support@mydadomain.com
                    </b-dropdown-item>
                    <b-dropdown-item href="tel:18777721982">
                        Call us at:
                        <br />1-877-772-1982
                    </b-dropdown-item>
                    <!-- <b-dropdown-item href="#">Chat with us!</b-dropdown-item> -->
                </b-nav-item-dropdown>

                <b-nav-item-dropdown v-if="isAdmin || isMarketingAdmin" text="Admin" right>
                    <b-dropdown-item href="/admin/globalstats/">Global Stats</b-dropdown-item>
                    <b-dropdown-item v-if="isAdmin" to="/admin/approvalqueue"
                        >Approval Queue</b-dropdown-item
                    >
                    <b-dropdown-item v-if="isAdmin" href="/admin/complianceregexes/"
                        >Compliance Regexes</b-dropdown-item
                    >
                    <b-dropdown-item v-if="isAdmin" to="/admin/emaillogs"
                        >Email Logs Lookup</b-dropdown-item
                    >
                    <b-dropdown-item v-if="isAdmin" to="/admin/agencyintern"
                        >Agency Intern Form</b-dropdown-item
                    >
                </b-nav-item-dropdown>

                <b-nav-item-dropdown v-if="!restricted" right>
                    <!-- Using button-content slot -->
                    <template slot="button-content">
                        <Icon i="user" />
                        &nbsp;{{ account.full_name }}
                        (
                        <strong>{{ account.agent_code }}</strong>
                        )
                    </template>
                    <b-dropdown-item v-if="show_account_select" href="/accounts/"
                        >Change Account</b-dropdown-item
                    >
                    <b-dropdown-divider v-if="show_account_select" />
                    <!-- <b-dropdown-item href="#">Office Info</b-dropdown-item> -->
                    <b-dropdown-item v-if="isAdmin" :href="helpscoutUrl" target="_blank">
                        <Icon i="life-ring" /> Open HS Ticket
                    </b-dropdown-item>
                    <b-dropdown-item to="/team/">Team Permissions</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item href="/profile/">My Profile</b-dropdown-item>
                    <b-dropdown-item to="/payment/">Payment Settings</b-dropdown-item>
                    <b-dropdown-item to="/billing/">Billing History</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item to="/logout/">Signout</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

            <!-- <b-button :pressed.sync="show_recent_alerts">Icon</b-button>
            <RecentAlerts v-if="show_recent_alerts"/>-->
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';

import RecentAlerts from '@/components/RecentAlerts.vue';
export default {
    name: 'CustomerHeader',
    components: {
        RecentAlerts,
    },
    props: {
        restricted: Boolean,
        account: Object,
        user: Object,
    },
    data() {
        return {
            show_recent_alerts: false,
        };
    },
    computed: {
        helpscoutUrl() {
            return this.account
                ? `https://secure.helpscout.net/mailbox/b7d3f054550980ab/new-ticket/?name=${this.account.full_name}&email=${this.account.agency.agent.email_address}&phone=${this.account.agency.office.phone_number}&type=phone`
                : '';
        },
        show_account_select() {
            return this.user && this.user.num_available_accounts > 1;
        },
        ...mapGetters(['isAdmin', 'isMarketingAdmin', 'isIntern']),
    },
    methods: {
        selectUser() {
            this.$store.dispatch('select_user');
        },
    },
};
</script>

<style lang="scss" scope>
.dropdown-menu {
    @extend .depth-1;
    background-color: $paper-color;
    border-radius: 2px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
    border-width: 0px;

    .dropdown-item {
        outline: 0;

        &:focus {
            background-color: $gray-200;
        }

        &.active {
            background-color: $primary;
            color: $white;
        }
    }
}
</style>
