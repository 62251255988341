<template>
    <b-form @submit.prevent.stop="onSubmit" class="page_main_col col-12 offset-lg-1 col-lg-10">
        <div v-if="show_search" class="paper p-3 text-left">
            <h3>Parameters</h3>
            <b-container class="m-0 full-width">
                <b-row>
                    <b-col>
                        <b-form-group label="from email">
                            <b-input
                                v-model="from_email"
                                id="from-email"
                                placeholder="from@domain.com" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="to email">
                            <b-input v-model="to_email" id="to-email" placeholder="to@email.com" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
            <div class="d-flex justify-content-end">
                <b-button variant="primary" type="submit">Search</b-button>
            </div>
        </div>

        <div v-if="loading || logs.length" class="paper mt-3 p-3 text-left">
            <div v-if="loading" class="font-weight-bold p-3">
                <Icon i="spinner" spin /> Loading Email Lookup...
            </div>
            <div v-else>
                <b-input
                    class="search-bar mr-sm-2 mb-sm-0"
                    id="searchInput"
                    v-model="filter"
                    placeholder="Type to Search" />
                <b-table
                    class="table"
                    small
                    responsive
                    hover
                    :items="logs"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    @row-clicked="(item) => $set(item, '_showDetails', !item._showDetails)">
                    <template #cell(arrival)="data">
                        <div class="date-cell">
                            {{ formatDate(data.value) }}
                        </div>
                    </template>
                    <template #cell(from)="data">
                        <div class="email-cell">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(to)="data">
                        <div class="email-cell">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(event)="data">
                        <b-badge :variant="getBadge(data.value)">
                            {{ data.value }}
                        </b-badge>
                    </template>
                    <template #cell(message)="data">
                        <div class="msg-preview">
                            {{ shorthandMessage(data.value) }}
                        </div>
                    </template>
                    <template #row-details="row">
                        <pre class="msg-pre">{{ formatMessage(row.item.message) }}</pre>
                    </template>
                </b-table>
                <b-pagination
                    align="center"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage" />
            </div>
        </div>
    </b-form>
</template>

<script>
import axios from 'axios';
export default {
    name: 'EmailLogs',
    data() {
        return {
            show_search: true,
            from_email: '',
            to_email: '',
            logs: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 30,
            filter: null,
            loading: null,
            fields: [
                { key: 'arrival', label: 'Date & Time' },
                { key: 'from' },
                { key: 'to' },
                { key: 'event' },
                { key: 'message' },
            ],
        };
    },
    mounted() {
        this.to_email = this.$route.params.email || '';

        if (this.to_email !== '') {
            this.show_search = false;
            this.onSubmit();
        }
    },
    methods: {
        getBadge(text) {
            text = text.toLowerCase();
            if (text === 'delivered') {
                return 'success';
            }
            if (text === 'hard bounce' || text === 'rejected') {
                return 'danger';
            }
            return 'secondary';
        },
        onSubmit() {
            this.loading = true;
            axios
                .get(`/backend/api/frontend/admin/emailogs`, {
                    params: { from: this.from_email, to: this.to_email },
                })
                .then((resp) => {
                    this.logs = resp.data.map((log) => ({ ...log, _showDetails: false }));
                    this.totalRows = this.logs.length;
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem searching the logs, please try again later',
                        varient: 'danger',
                    });
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        shorthandMessage(message) {
            try {
                const jsonData = JSON.parse(message);
                if (jsonData.msg) {
                    return jsonData.msg;
                }
                return message;
            } catch (error) {
                return message;
            }
        },
        formatMessage(message) {
            try {
                return JSON.parse(message);
            } catch (error) {
                return message;
            }
        },
        formatDate(dateStr) {
            const date = new Date(dateStr);
            return date.toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.full-width {
    max-width: 100%;
}
.table {
    font-size: 14px;
}
.table:hover {
    cursor: pointer;
}
.msg-preview {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.email-cell {
    max-width: 300px;
    overflow-wrap: break-word;
}
.date-cell {
    min-width: 110px;
}
.msg-pre {
    background-color: #f4f3f4;
    padding: 1rem;
    margin-bottom: 0;
}
</style>
